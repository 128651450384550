export class HttpError extends Error implements App.Error {
  statusCode: number;
  statusText: string;

  constructor({
    statusCode,
    statusText,
    message = `${statusCode} ${statusText}`
  }: {
    message?: string;
    statusCode: number;
    statusText: string;
  }) {
    super(message);

    this.statusCode = statusCode;
    this.statusText = statusText;
  }
}

export class NotFoundError extends HttpError {
  constructor(message?: string) {
    super({ message, statusCode: 404, statusText: 'Not Found' });
  }
}

export class ResponseError extends HttpError {
  response: Response;

  constructor(response: Response, message?: string) {
    super({
      message,
      statusCode: response.status,
      statusText: response.statusText
    });

    this.response = response;
  }
}

export const logError = (error: unknown) => {
  if (error instanceof Error) {
    console.error(error?.stack ?? error.message);
  } else {
    console.error(error);
  }
};

export const reportError = (error: unknown) => {
  // TODO report to Sentry? [ASTRO-1020]

  logError(error);
};
